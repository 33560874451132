<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" />
  </div>
</template>
<script>
import Dialog from "@/components/Dialog";
import TablePage from "@/components/tablePage";
import {
  templateList,
  templateRemove,
  findTemplateGoods,
} from "@/api/O2OMall/manage/freight";
export default {
  components: { TablePage, Dialog },
  dicts: ["fetch_type"],
  data() {
    return {
      dialogOptions: {
        show: false,
        type: "Collection",
        title: "",
      },
      options: {
        uuid: "fe138860-2ca1-3e70-3667-359b711c7e7d",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: templateList,
        body: {},
        listNo: true, // 序号
        title: "运费管理",
        check: [], // 选中数据
        rowKey: "templateId",
        search: [
          {
            type: "filters",
            tip: "全部/模板编号/模板名称",
            isRestore: true,
            model: "",
            filters: [
              { filter: "query", label: "全部" },
              { filter: "templateNos", label: "模板编号" },
              { filter: "templateNames", label: "模板名称" },
            ],
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
          { type: "button", tip: "重置", click: "reset" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
            disabled: false,
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            alertText: "确认要删除选中运费模板吗？",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          // {
          //   prop: "templateNo",
          //   label: "模板编号",
          //   type: "link",
          //   click: "routerLink",
          //   minWidth: 180,
          // },
          {
            prop: "templateNo",
            label: "模板编号",
            minWidth: 155,
            type: "link",
            click: "routerLink",
            minWidth: 180,
          },
          {
            prop: "templateName",
            label: "模板名称",
            minWidth: 155,
          },
          {
            prop: "useGoodsCount",
            label: "商品使用个数",
            minWidth: 120,
            type: "link",
            click: "gooduseNumLink",
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 120,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 160,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 120,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 160,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 160,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "routerLink":
          {
            const templateId = row.templateId;
            this.$router.push({
              name: "AddFreightTemp",
              query: {
                templateId: templateId,
                type: "Update",
              },
            });
          }
          break;
        case "gooduseNumLink": {
          this.dialogOptions = {
            ...this.dialogOptions,
            show: true,
            width: "800px",
            title: row.templateName,
            data: {
              height: 300,
              getListApi: findTemplateGoods,
              defaultBody: { templateId: row.templateId },
              list: [],
              columns: [
                {
                  prop: "goodsNo",
                  label: "商品编码",
                  minWidth: 150,
                },
                // {
                //   type: "image",
                //   prop: "goodsUrl",
                //   label: "图片",
                //   minWidth: 150,
                // },
                {
                  prop: "goodsName",
                  label: "商品名称",
                  minWidth: 150,
                },
                // {
                //   prop: "categoryName",
                //   label: "商城分类",
                //   minWidth: 150,
                // },
              ],
            },
          };
          break;
        }
        case "add":
          {
            this.$router.push({
              name: "AddFreightTemp",
              query: {
                type: "Add",
              },
            });
          }
          break;
        case "del":
          try {
            await templateRemove(this.options.check.map((x) => x.templateId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
